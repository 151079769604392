import React, { useEffect, useState } from 'react';

import {
    Typography,
    TextField,
    Button,
    Grid,
    Container,
    MenuItem,
    Select,
} from '@mui/material';
import { END_POINT_MEROSHARE_BANK_DETAILS, END_POINT_MEROSHARE_LINKED_BANK_ACCOUNTS } from '../../utils/Constants';
import { apiFetch } from '../../utils/Http';
import { getToken } from '../../App';


const UpdateMeroshareBankDetails = (props) => {


    const [banks, setBanks] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const list = await apiFetch(END_POINT_MEROSHARE_LINKED_BANK_ACCOUNTS, 'GET', null, getToken());
                setBanks(list);
            } catch (e) {
                console.error(e);
                props.setError(e.message);
            }
        }
        fetchData();
    }, [props.token, props]);

    const { bankDetails } = props.data.meroshare;

    const [bankId, setBankId] = useState(bankDetails?.bankId ?? '');
    const [crnNumber, setCrnNumber] = useState(bankDetails?.crnNumber ?? '');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = {
            bankId,
            crnNumber
        };


        try {
            props.setLoading(true);
            const response = await apiFetch(END_POINT_MEROSHARE_BANK_DETAILS, 'POST', JSON.stringify(formData), getToken());
            props.setMessage(response.message);
            props.onSuccess();
        } catch (e) {
            console.error(e);
            props.setError(e.message);
        } finally {
            props.setLoading(false);
        }
    };

    return (
        <Container sx={{ p: 5 }}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Update MeroShare Bank Details</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <label htmlFor="bankId">Bank</label>
                        <Select
                            id='bankId'
                            fullWidth
                            variant="outlined"
                            value={bankId}
                            required
                            onChange={(e) =>
                                setBankId(e.target.value)
                            }>
                            {banks.map(bank => (
                                <MenuItem key={bank.code} value={bank.id}>{bank.name}</MenuItem>
                            ))}
                        </Select>
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            label="CRN Number"
                            variant="outlined"
                            margin='normal'
                            fullWidth
                            value={crnNumber}
                            onChange={(e) => setCrnNumber(e.target.value)}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>

        </Container>
    );
};

export default UpdateMeroshareBankDetails;
