import React from 'react';
import { Typography, Grid, Container, IconButton, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { Edit } from '@mui/icons-material';

const MeroShare = (props) => {
    const { meroshare } = props.data;

    return (
        <Container sx={{ p: 5 }}>

            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">MeroShare</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Credentials"
                            value={meroshare?.credentials ? 'Provided' : 'Not Provided'}
                            fullWidth
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        component={Link}
                                        to="/meroshare/credentials">
                                        <Edit />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Bank Details"
                            value={meroshare?.bankDetails ? 'Provided' : 'Not Provided'}
                            fullWidth
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        component={Link}
                                        to="/meroshare/bankDetails">
                                        <Edit />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default MeroShare;