import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { CircularProgress } from '@mui/material';

export default function SpinnerDialog(props) {
    if (!props.open) {
        return null; // Don't render anything if props.open is false
    }
    return (
        <Dialog
            open={props.open}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    overflow: 'hidden'
                }
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '150px' }}>
                <CircularProgress color="primary" />
            </div>
        </Dialog>
    );
}