import React, { useState } from 'react';
import {
    Typography,
    Select,
    MenuItem,
    TextField,
    Button,
    Grid,
    Container,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { END_POINT_SUBSCRIPTION } from '../../utils/Constants';
import { getToken } from '../../App';
import { apiFetch } from '../../utils/Http';

const UpdateSubscription = (props) => {
    const { subscription } = props.data;

    const [subscriptionEnabled, setSubscriptionEnabled] = useState(
        subscription != null
    );
    const [filtersEnabled, setFiltersEnabled] = useState(
        subscription?.filters != null
    );

    const [autoApplyEnabled, setAutoApplyEnabled] = useState(
        subscription?.autoApplyEnabled === true
    );

    const [filterShareTypeNames, setFilterShareTypeNames] = useState(
        subscription?.filters?.filterShareTypeNames ?? []
    );
    const [filterShareGroupNames, setFilterShareGroupNames] = useState(
        subscription?.filters?.filterShareGroupNames ?? []
    );
    const [filterMinAllowedUnitNotMoreThan, setFilterMinAllowedUnitNotMoreThan] = useState(
        subscription?.filters?.filterMinAllowedUnitNotMoreThan ?? ''
    );
    const [filterPricePerUnitNotLessThan, setFilterPricePerUnitNotLessThan] = useState(
        subscription?.filters?.filterPricePerUnitNotLessThan ?? ''
    );
    const [filterPricePerUnitNotMoreThan, setFilterPricePerUnitNotMoreThan] = useState(
        subscription?.filters?.filterPricePerUnitNotMoreThan ?? ''
    );

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = subscriptionEnabled ? {
            autoApplyEnabled, filters: filtersEnabled ? {
                filterShareTypeNames,
                filterShareGroupNames,
                filterMinAllowedUnitNotMoreThan,
                filterPricePerUnitNotLessThan,
                filterPricePerUnitNotMoreThan
            } : null
        } : null;


        try {
            props.setLoading(true);
            const response = await apiFetch(END_POINT_SUBSCRIPTION, 'POST', JSON.stringify(formData), getToken());
            props.setMessage(response.message);
            props.onSuccess();
        } catch (e) {
            console.error(e);
            props.setError(e.message);
        } finally {
            props.setLoading(false);
        }
    };

    return (
        <Container sx={{ p: 5 }}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Update Subscription</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={subscriptionEnabled}
                                    onChange={(e) => setSubscriptionEnabled(e.target.checked)}
                                    color="primary" />
                            }
                            label="Enable Subscription"
                        />
                    </Grid>
                    {subscriptionEnabled && <>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filtersEnabled}
                                        onChange={(e) => setFiltersEnabled(e.target.checked)}
                                        color="primary" />
                                }
                                label="Enable Filters"
                            />
                        </Grid>

                        {filtersEnabled && <>
                            <Grid item xs={12}>
                                <label htmlFor="filterShareTypeNames">Filter Share Types</label>
                                <Select
                                    id="filterShareTypeNames"
                                    multiple
                                    fullWidth
                                    value={filterShareTypeNames}
                                    onChange={(e) =>
                                        setFilterShareTypeNames(
                                            e.target.value
                                        )
                                    }
                                    renderValue={(selected) => (
                                        <div>
                                            {selected.map((value) => (
                                                <div key={value}>{value}</div>
                                            ))}
                                        </div>
                                    )}
                                >
                                    <MenuItem value="IPO">IPO</MenuItem>
                                    <MenuItem value="FPO">FPO</MenuItem>
                                    <MenuItem value="RIGHT SHARE">RIGHT SHARE</MenuItem>
                                    <MenuItem value="RESERVED">RESERVED</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <label htmlFor="filterShareGroupNames">Filter Share Groups</label>
                                <Select
                                    id="filterShareGroupNames"
                                    multiple
                                    fullWidth
                                    value={filterShareGroupNames}
                                    onChange={(e) =>
                                        setFilterShareGroupNames(
                                            e.target.value
                                        )
                                    }
                                    renderValue={(selected) => (
                                        <div>
                                            {selected.map((value) => (
                                                <div key={value}>{value}</div>
                                            ))}
                                        </div>
                                    )}
                                >
                                    <MenuItem value="Ordinary Shares">Ordinary Shares</MenuItem>
                                    <MenuItem value="Debentures">Debentures</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Filter Minimum Allowed Unit Not More Than"
                                    type="number"
                                    variant="outlined"
                                    fullWidth
                                    value={filterMinAllowedUnitNotMoreThan}
                                    onChange={(e) => setFilterMinAllowedUnitNotMoreThan(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Filter Price Per Unit Not Less Than"
                                    type="number"
                                    variant="outlined"
                                    fullWidth
                                    value={filterPricePerUnitNotLessThan}
                                    onChange={(e) => setFilterPricePerUnitNotLessThan(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Filter Price Per Unit Not More Than"
                                    type="number"
                                    variant="outlined"
                                    fullWidth
                                    value={filterPricePerUnitNotMoreThan}
                                    onChange={(e) => setFilterPricePerUnitNotMoreThan(e.target.value)}
                                    required
                                />
                            </Grid>
                        </>}

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={autoApplyEnabled}
                                        onChange={(e) => setAutoApplyEnabled(e.target.checked)}
                                        color="primary" />
                                }
                                label="Auto Apply"
                            />
                        </Grid>
                    </>

                    }

                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default UpdateSubscription;
