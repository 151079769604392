import React from 'react';
import { Typography, Grid, Container, TextField } from '@mui/material';

const UserProfile = (props) => {
    const {
        firstName,
        lastName,
        email,
        emailVerified,
        joinedAt,
        updatedAt
    } = props.data;

    return (
        <Container sx={{ p: 5 }}>

            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Profile</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            value={`${firstName} ${lastName}`}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            value={email}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email Verified"
                            value={emailVerified ? 'Yes' : 'No'}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Joined At"
                            value={new Date(joinedAt).toLocaleString()}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Updated At"
                            value={new Date(updatedAt).toLocaleString()}
                            fullWidth
                            disabled
                        />
                    </Grid>
                </Grid>
            </form>
        </Container>


    );
};

export default UserProfile;