import './App.css';
import React, { useCallback, useEffect, useState } from 'react';
import Profile from './pages/loggedin/Profile';
import Login from './pages/prelogin/Login';
import Signup from './pages/prelogin/Signup';
import UpdateMeroshareCredentials from './pages/loggedin/UpdateMeroshareCredentials';
import UpdateMeroshareBankDetails from './pages/loggedin/UpdateMeroshareBankDetails';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PageNotFound from './pages/common/404';
import DashboardHeader from './components/DashboardHeader';
import ChangePassword from './pages/common/ChangePassword';
import { apiFetch } from './utils/Http';
import Stocks from './pages/loggedin/Stocks';
import { END_POINT_PROFILE } from './utils/Constants';
import UpdateSubscription from './pages/loggedin/UpdateSubscription';
import MeroShare from './pages/loggedin/MeroShare';
import SpinnerDialog from './components/spinner/SpinnerDialog';
import ErrorSnackbar from './components/snackbar/ErrorSnackbar';
import SuccessSnackbar from './components/snackbar/SuccessSnackbar';

import { CircularProgress } from '@mui/material';

export function getToken() {
  return localStorage.getItem("token")
}

function App() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [token, setToken] = useState(getToken());
  const [profile, setProfile] = useState(null);

  const fetchProfile = useCallback(async () => {
    if (token) {
      localStorage.setItem("token", token);
      try {
        const data = await apiFetch(END_POINT_PROFILE, 'GET', null, token);
        setProfile(data);
      } catch (e) {
        console.error(e);
        setError(e.message);
        setToken(null);
      }
    } else {
      localStorage.removeItem("token");
      setProfile(null);
    }
  }, [token]);

  useEffect(() => { fetchProfile(); }, [fetchProfile]);

  async function logout() {
    setToken(null);
  }

  if (token == null) {
    return <>
      <Router>
        <Routes>
          <Route exact path='/' element={
            <Login setToken={setToken} setLoading={setLoading} setError={setError} setMessage={setMessage} />
          } />
          <Route exact path='/signup' element={
            <Signup setLoading={setLoading} setError={setError} setMessage={setMessage} />
          } />
          <Route exact path='/change_password' element={
            <ChangePassword setLoading={setLoading} setMessage={setMessage} setError={setError} logout={logout} />
          } />
          <Route path="*" element={
            <PageNotFound />
          } />
        </Routes>
      </Router>
      <SpinnerDialog open={loading} />
      <ErrorSnackbar message={error} onClose={() => setError(null)} />
      <SuccessSnackbar message={message} onClose={() => setMessage(null)} />
    </>

  } else if (profile == null) {
    return <>
      <center>
        <h4>Fetching profile..</h4>
        <CircularProgress />
      </center>
    </>
  } else return (
    <>
      <Router>
        <div >
          <DashboardHeader data={profile} logout={logout} />
          <Routes>
            <Route exact path='/' element={
              <Stocks data={profile} setError={setError} setMessage={setMessage} setLoading={setLoading}/>
            }
            />
            <Route exact path='/profile' element={
              <Profile data={profile} />
            }
            />
            <Route exact path='/change_password' element={
              <ChangePassword setError={setError} setMessage={setMessage} setLoading={setLoading} logout={logout} />
            }
            />
            <Route exact path='/meroshare' element={
              <MeroShare data={profile} />
            }
            />
            <Route exact path='/meroshare/credentials' element={
              <UpdateMeroshareCredentials setError={setError} setMessage={setMessage} setLoading={setLoading} onSuccess={fetchProfile} data={profile} />
            }
            />

            <Route exact path='/meroshare/bankDetails' element={
              <UpdateMeroshareBankDetails setError={setError} setMessage={setMessage} setLoading={setLoading} onSuccess={fetchProfile} data={profile} />
            }
            />

            <Route exact path='/update_subscription' element={
              <UpdateSubscription setError={setError} setMessage={setMessage} setLoading={setLoading} onSuccess={fetchProfile} data={profile} />
            }
            />

            <Route path="*" element={
              <PageNotFound />
            }
            />

          </Routes>
        </div>
      </Router>
      <SpinnerDialog open={loading} />
      <ErrorSnackbar message={error} onClose={() => setError(null)} />
      <SuccessSnackbar message={message} onClose={() => setMessage(null)} />
    </>
  );

}

export default App;
