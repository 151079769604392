
const BASE_URL =
  "https://stock-wiz-run-service-uqmk67kmaa-uc.a.run.app"
//  "http://127.0.0.1:8080"

export const END_POINT_SIGNUP = BASE_URL + "/signup"
export const END_POINT_TOKEN = BASE_URL + "/token"
export const END_POINT_RESET_PASSWORD = BASE_URL + "/resetPassword"
export const END_POINT_CHANGE_PASSWORD = BASE_URL + "/changePassword"
export const END_POINT_PROFILE = BASE_URL + "/profile"

export const END_POINT_SUBSCRIPTION = BASE_URL + "/subscription"
export const END_POINT_MEROSHARE_CREDENTIALS = BASE_URL + "/meroshare/credentials"
export const END_POINT_MEROSHARE_LINKED_BANK_ACCOUNTS = BASE_URL + "/meroshare/linkedBankAccounts"
export const END_POINT_MEROSHARE_BANK_DETAILS = BASE_URL + "/meroshare/bankDetails"

export const END_POINT_STOCKS = BASE_URL + "/stocks"
export const END_POINT_STOCK_APPLY = BASE_URL + "/stocks/apply"

export const PASSWORD_REGEX_EXP = new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!.* ).{9,16}")

