import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const SuccessSnackbar = ({ message, onClose }) => {
    return (
        <Snackbar open={message != null} autoHideDuration={5000} onClose={onClose} >
            <Alert severity="success" onClose={onClose} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default SuccessSnackbar;