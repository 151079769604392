import React, { useCallback, useEffect, useState } from 'react';
import { Chip, Container, Grid, List, ListItem, ListItemText, Typography, Button } from '@mui/material';
import { END_POINT_STOCKS, END_POINT_STOCK_APPLY } from '../../utils/Constants';
import { getToken } from '../../App';
import { apiFetch } from '../../utils/Http';

const Stocks = (props) => {
    const [stocks, setStocks] = useState(null);
    const { _id } = props.data;
    const { setError, setLoading, setMessage } = props;
    const fetchData = useCallback(async () => {
        try {
            const data = await apiFetch(END_POINT_STOCKS, 'GET', null, getToken());
            setStocks(data);
        } catch (e) {
            console.error(e);
            setError(e.message);
        }
    }, [setError]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    const handleApply = async (companyShareId, kitta) => {

        const formData = {
            companyShareId,
            kitta
        };

        try {
            setLoading(true);
            const response = await apiFetch(END_POINT_STOCK_APPLY, 'POST', JSON.stringify(formData), getToken());
            setMessage(response.message);
            fetchData();
        } catch (e) {
            console.error(e);
            setError(e.message);
            fetchData();
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container sx={{ p: 5 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">Stocks</Typography>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <List>
                    {stocks?.map((stock) => {
                        const remark = stock.history.find((element) => element.userId === _id)?.remark ?? "New"
                        return (
                            <>
                                <ListItem key={stock._id} disablePadding>
                                    <ListItemText>
                                        <Typography >
                                            <Chip label={stock.shareGroupName} /> <Chip label={stock.shareTypeName} /> <Chip label={stock.subGroup} />
                                        </Typography >
                                        <Typography >
                                            Company Name: {stock.companyName}
                                        </Typography >
                                        <Typography >
                                            Minimum Unit: {stock.minUnit}
                                        </Typography >
                                        <Typography >
                                            Per Unit: {stock.sharePerUnit}
                                        </Typography >
                                        <Typography >
                                            <Chip label={remark} />
                                        </Typography >
                                    </ListItemText>
                                    {
                                        (remark !== "APPLIED") && <Button variant="contained" onClick={() => handleApply(stock._id, stock.minUnit)}>Apply</Button>
                                    }

                                </ListItem>
                                <br />
                            </>
                        );
                    })}
                </List>
            </Grid>
        </Container>
    );
};

export default Stocks;