import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Copyright } from '../../components/Copyright';
import { Link } from '@mui/material';
import { Link as RouteLink } from 'react-router-dom';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import { apiFetch } from '../../utils/Http';
import { END_POINT_TOKEN } from '../../utils/Constants';

export default function Login(props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            email,
            password
        };
       
        try {
            props.setLoading(true);
            const data = await apiFetch(END_POINT_TOKEN, 'POST', JSON.stringify(formData));
            props.setToken(data.accessToken);
            props.setLoading(false);
        } catch (e) {
            console.error(e);
            props.setError(e.message);
            props.setLoading(false);
        }

    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Log in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Email Address"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    > Login
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <ForgotPasswordDialog setLoading={props.setLoading} />
                        </Grid>
                        <Grid item>
                            Don't have an account?  <RouteLink to="/signup">
                                <Link variant="body2">
                                    Sign Up
                                </Link>
                            </RouteLink>

                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}