import React, { useState } from 'react';
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Copyright } from '../../components/Copyright';
import { Link as RouteLink } from 'react-router-dom';
import { apiFetch } from '../../utils/Http';
import { END_POINT_SIGNUP, PASSWORD_REGEX_EXP } from '../../utils/Constants';

const Signup = (props) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState('');

    const handlePasswordChange = (value) => {
        setIsPasswordValid(value === "" || PASSWORD_REGEX_EXP.test(value));
        setPassword(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = {
            firstName,
            lastName,
            email,
            password,
            confirmPassword
        };


        try {
            props.setLoading(true);
            const response = await apiFetch(END_POINT_SIGNUP, 'POST', JSON.stringify(formData));
            props.setMessage(response.message);
        } catch (e) {
            console.error(e);
            props.setError(e.message);
        } finally {
            props.setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>

                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>

                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

                    <TextField
                        autoComplete="fname"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        label="First Name"
                        autoFocus
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />


                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        margin="normal"
                        label="Last Name"
                        autoComplete="lname"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />


                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        margin="normal"
                        label="Email Address"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />


                    <TextField
                        helperText={!isPasswordValid ? "Password must contain one digit from 1 to 9, one lowercase letter, one uppercase letter, one special character, no space and it must be 9-16 characters long" : ""}
                        onChange={(e) => handlePasswordChange(e.target.value)}
                        error={!isPasswordValid}
                        variant="outlined"
                        required
                        fullWidth
                        margin="normal"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        value={password}
                    />


                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        margin="normal"
                        label="Confirm Password"
                        type="password"
                        autoComplete="current-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={confirmPassword !== password}
                        helperText={confirmPassword !== password ? "Confirm password didn't match" : ""}

                    />


                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    > Sign Up
                    </Button>

                    <Grid container justify="flex-end">
                        <Grid item>
                            Already have an account? <RouteLink to="/">
                                <Link variant="body2">
                                    Sign in
                                </Link>
                            </RouteLink>

                        </Grid>
                    </Grid>
                </Box>

            </Box>
            <Copyright sx={{ mt: 5, mb: 4 }} />

        </Container>
    );
};

export default Signup;
