import React, { useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    Typography,
    Button,
    Grid,
    Container,
    TextField,
} from '@mui/material';
import { getToken } from '../../App';
import { apiFetch } from '../../utils/Http';
import { END_POINT_CHANGE_PASSWORD } from '../../utils/Constants';

const ChangePassword = (props) => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = {
            newPassword,
            confirmPassword
        };


        try {
            props.setLoading(true);
            const response = await apiFetch(END_POINT_CHANGE_PASSWORD, 'POST', JSON.stringify(formData), searchParams.get("token") ?? getToken());
            props.setMessage(response.message);
            props.logout();
        } catch (e) {
            console.error(e);
            props.setError(e.message);
        } finally {
            props.setLoading(false);

        }

        navigate('/')
    };

    return (
        <Container sx={{ p: 5 }}>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Change Password</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="New Password"
                            variant="outlined"
                            margin='normal'
                            type='password'
                            fullWidth
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Confirm Password"
                            variant="outlined"
                            margin='normal'
                            type='password'
                            fullWidth
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default ChangePassword;
