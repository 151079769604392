import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

function PageNotFound(props) {
    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Box m={5}>
                <Typography variant="h1" sx={{ fontSize: '7.5em', fontWeight: 'bold' }}>
                    404
                </Typography>
                <Typography variant="h3" fontWeight="bold">
                    UH OH! You're lost
                </Typography>
                <Typography variant="body1">
                    The page you are looking for does not exist. How you got here is a mystery. But you can click the button below
                    to go back to the homepage
                </Typography>
                <Button
                    variant="outlined"
                    component={Link}
                    to="/"
                    color="primary"
                    sx={{ mt: 2 }}
                >
                    HOME
                </Button>
            </Box>
        </Container>
    );
}

export default PageNotFound;