import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from '@mui/material';
import { useState } from 'react';
import { END_POINT_RESET_PASSWORD } from '../../utils/Constants';
import { apiFetch } from '../../utils/Http';

export default function ForgotPasswordDialog(props) {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        handleClose();
        const formData = {
            email
        };

        props.setLoading(true);
        try {
            const response = await apiFetch(END_POINT_RESET_PASSWORD, 'POST', JSON.stringify(formData));
            props.setMessage(response.message);
        } catch (e) {
            console.error(e);
        } finally {
            props.setLoading(false);
        }

    };

    return (
        <div>
            <Link onClick={handleClickOpen} href="#" variant="body2">
                Forgot password?
            </Link>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle> Forgot password?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To reset your password, please enter your email address here. We
                        will send you a password reset link.
                    </DialogContentText>
                    <TextField
                        value={email}
                        onChange={(e) =>
                            setEmail(e.target.value)
                        }
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Reset</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}