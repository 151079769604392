

export async function apiFetch(url, method, body, token) {
    const response = await fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: body,
    });


    const responseData = await response.json();

    if (!response.ok) {
        throw new Error(responseData.message);
    }

    return responseData;

}