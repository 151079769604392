import React, { useEffect, useState } from 'react';
import {
    Typography,
    TextField,
    Button,
    Grid,
    Container,
    Select,
    MenuItem,
} from '@mui/material';
import { apiFetch } from '../../utils/Http';
import { END_POINT_MEROSHARE_CREDENTIALS } from '../../utils/Constants';
import { getToken } from '../../App';

const UpdateMeroshareCredentials = (props) => {


    const credentials = props.data?.meroshare?.credentials;

    const [clientId, setClientId] = useState(credentials?.clientId ?? '');
    const [username, setUsername] = useState(credentials?.username ?? '');
    const [password, setPassword] = useState('');
    const [transactionPin, setTransactionPin] = useState('');


    const [clients, setClients] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const list = await apiFetch('https://webbackend.cdsc.com.np/api/meroShare/capital/', 'GET');
                setClients(list);
            } catch (e) {
                console.error(e);
            }
        }
        fetchData();
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = {
            clientId,
            username,
            password,
            transactionPin
        };


        try {
            props.setLoading(true);
            const response = await apiFetch(END_POINT_MEROSHARE_CREDENTIALS, 'POST', JSON.stringify(formData), getToken());
            props.setMessage(response.message);
            props.onSuccess();
        } catch (e) {
            console.error(e);
            props.setError(e.message);
        } finally {
            props.setLoading(false);
        }
    }

    return (
        <Container sx={{ p: 5 }}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Update Meroshare Credentials</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <label htmlFor="client">Depository Participants</label>
                        <Select
                            id='client'
                            fullWidth
                            variant="outlined"
                            value={clientId}
                            required
                            onChange={(e) =>
                                setClientId(e.target.value)
                            }>
                            {clients.map(client => (
                                <MenuItem key={client.code} value={client.id}>{client.name}</MenuItem>
                            ))}
                        </Select>
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            label="Username"
                            variant="outlined"
                            fullWidth
                            margin='normal'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Password"
                            type="password"
                            variant="outlined"
                            margin='normal'
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Transaction PIN"
                            type="number"
                            variant="outlined"
                            margin='normal'
                            fullWidth
                            value={transactionPin}
                            onChange={(e) => setTransactionPin(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>

        </Container>
    );
};

export default UpdateMeroshareCredentials;
